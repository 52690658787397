<template>
  <div class="contactDiv">
    <div class="container">
      <b-row>
        <b-col cols="12">
          <h3><span class="tit-L">CONTACT&nbsp;&nbsp;&nbsp;&nbsp;</span>聯絡我們<br/><span class="tit-S">CONTACT</span>
          </h3>
        </b-col>
      </b-row><!--row-->
      <b-row>
        <b-col cols="12">
          <p class="p1">您可以在這裡留下有關我們的公司或產品的任何建議，<br/>也歡迎提案異業合作互相提升能見度及業績。</p>
          <p class="p2">代理商: Innovision 優視達<br/>服務專線: 07-5561219</p>
        </b-col>
      </b-row><!--row-->
      <b-row>
        <b-col cols="12" sm="6">
          <input type="text" class="textInput" v-model="contact.name" placeholder="姓名">
        </b-col>
        <b-col cols="12" sm="6">
          <input type="text" class="textInput" v-model="contact.cellphone" placeholder="電話">
        </b-col>
        <b-col cols="12">
          <input type="text" class="textInput" v-model="contact.email" placeholder="E-mail">
        </b-col>
        <b-col cols="12">
          <input type="text" class="textInput" v-model="contact.question" placeholder="留言">
        </b-col>
        <b-col cols="12">
          <a href="#" class="btnSend contact_PopWd" @click.prevent="submit">送出</a>
        </b-col>
      </b-row><!--row-->
    </div><!--container-->
  </div><!--end of contactDiv-->
</template>

<script>
import NoticeManager from '../utils/NoticeManager';
import APIService from '../utils/APIService';
export default {
  name: "Contact",
  metaInfo:{
    title: '聯絡我們 - Innovision 優視達光學',
  },
  data(){
    return {
      contact:null,
      defaultItem:{
        name:'',
        email:'',
        cellphone:'',
        question:'',
      },
    }
  },
  created() {
    this.reset();
  },
  mounted(){
    window.scrollTo(0, 800);
  },
  methods:{
    reset(){
      this.contact = Object.assign({}, this.defaultItem);
    },
    async submit(){
      if (this.contact.name === ''){
        this.showAlert('錯誤', '請輸入姓名');
        return;
      }

      if (this.contact.cellphone === ''){
        this.showAlert('錯誤', '請輸入電話');
        return;
      }

      if (this.contact.email === ''){
        this.showAlert('錯誤', '請輸入E-mail');
        return;
      }

      if (this.contact.question === ''){
        this.showAlert('錯誤', '請輸入留言');
        return;
      }

      const res = await APIService.saveContactInfo({
        info: this.contact
      });
      const result = res.data;
      if (result && result.code === 0){
        this.showAlert('提示', '您的訊息已寄出');
        this.reset();
      } else {
        this.showAlert('錯誤', result ? result.message : '儲存失敗');
      }
    },
    showAlert(title, message){
      NoticeManager.notifyObservers('saveContact', {
        title: title, message: message
      });
    },
  }
}
</script>

<style scoped>

</style>
