<template>
  <div>
    <b-row class="pro-tit">
      <b-col cols="12" md="9" offset-md="3">
        <h3><span class="tit-L">PRODUCTS&nbsp;&nbsp;&nbsp;&nbsp;</span>所有產品<br/><span class="tit-S">PRODUCTS</span></h3>
        <b-row>
          <b-col cols="4" lg="3">
            <a href="#" :class="`btnContacts ${tab === 0 ? 'now':''}`"
               @click.prevent="changeTab(0)">
              <span class="g"></span>
              <span class="w"></span>
              隱形眼鏡
            </a>
            <a href="#" :class="`btnContacts btnContacts-m ${tab === 0 ? 'now':''}`"
               @click.prevent="changeTab(0)">
              <span class="g"></span>
              <span class="w"></span>
              隱形眼鏡
            </a>
          </b-col>
          <b-col cols="4" lg="3">
            <a href="#" :class="`btnMakeup ${tab === 1 ? 'now':''}`"
               @click.prevent="changeTab(1)">
              <span class="g"></span>
              <span class="w"></span>
              彩妝商品
            </a>
          </b-col>
          <b-col cols="4" lg="3">
            <a href="#" :class="`btnLife ${tab === 2 ? 'now':''}`"
               @click.prevent="changeTab(2)">
              <span class="g"></span>
              <span class="w"></span>
              生活小物
            </a>
          </b-col>
        </b-row>
      </b-col>
    </b-row><!--row-->
    <b-row>
      <b-col cols="12" md="3">
        <div class="pro-menu">
          <a href="#" target="_self" class="btn-main" @click.prevent="changeTab(0)">隱形眼鏡</a>
          <div :class="`pro-menu-m ${showMenu ? 'pro-menu-m-open' : 'pro-menu-m-close'}`">
            <b-col cols="4" md="12" :class="`ulMenu ulMenu-open`">
              <a href="#" class="btn-ul" @click.prevent="changeMenu(-1)">所有產品
<!--                <span class="arrow">-->
<!--                          <span class="a-1"></span>-->
<!--                          <span class="a-2"></span>-->
<!--                        </span>-->
              </a>
            </b-col>
            <b-col cols="4" md="12" :class="`ulMenu ulMenu-close`">
              <a href="#" class="btn-ul" @click.prevent="changeMenu(0)">彩色日拋

              </a>
<!--              <ul>-->
<!--                <li><a href="#">CHIC55%<br/>UV。MOISTURE</a></li>-->
<!--                <li><a href="#">CHIC38%</a></li>-->
<!--              </ul>-->
            </b-col>
            <b-col cols="4" md="12" :class="`ulMenu ulMenu-close`">
              <a href="#" class="btn-ul" @click.prevent="changeMenu(1)">彩色月拋
<!--                <span class="arrow">-->
<!--                          <span class="a-1"></span>-->
<!--                          <span class="a-2"></span>-->
<!--                        </span>-->
              </a>
<!--              <ul>-->
<!--                <li><a href="#">VIRAL</a></li>-->
<!--                <li><a href="#">CHIC</a></li>-->
<!--                <li><a href="#">EYEDDiCT</a></li>-->
<!--                <li><a href="#">VIRAL</a></li>-->
<!--                <li><a href="#">CHIC</a></li>-->
<!--                <li><a href="#">EYEDDiCT</a></li>-->
<!--              </ul>-->
            </b-col>
            <a href="#" target="_self" class="btn-ul ul-2 col-xs-4 col-md-12" @click.prevent="changeMenu(2)">透明日拋</a>
          </div><!--pro-menu-m-->
          <a href="#" target="_self" class="btn-main" @click.prevent="changeTab(1)">彩妝商品</a>
          <a href="#" target="_self" class="btn-main" @click.prevent="changeTab(2)">生活小物</a>
        </div><!--pro-menu-->
      </b-col>
    </b-row><!--row-->
  </div>
</template>

<script>
import $ from 'jQuery';
import APIService from "@/utils/APIService";

export default {
  name: "ProductNav",
  data(){
    return {
      menus: [
        { key : 0, show: false},
        { key : 1, show: false},
      ],
      tab: 0 ,
      showMenu: false,
    };
  },
  created() {
    $(function(){
      $(".pro-menu-m").removeClass("pro-menu-m-close").addClass("pro-menu-m-open");
    });
    this.tab = this.$route.query.tab;
    this.showMenu = this.tab === 0;
    this.loadData();
  },
  mounted(){
    window.scrollTo(0, 400);
  },
  methods:{
    async loadData(){
      let cacheGroup = [];
      const query = { pageIndex: 1, limit: 100, type: 0, sortBy:'sort', sortByValue: 'ASC' };
      const res = await APIService.getProductList({ info: query });
      const result = res.data;

      const products = result.body.list ? result.body.list : [];
      for (let product of products){
        cacheGroup.push(product);
      }
      if (cacheGroup.length){
        console.log(`save cache_group == ${JSON.stringify(cacheGroup)}`);
        localStorage.setItem('cache_group', JSON.stringify(cacheGroup));
      }
    },
    changeMenu(menuKey){
      // this.menus[menuKey].show = !this.menus[menuKey].show;
      this.$emit('changeMenu', menuKey);
      this.$router.replace({ path:'/product', query: { tab: 0, menu: menuKey}});
    },
    changeTab(tab){
      this.tab = tab;
      this.showMenu = this.tab === 0;
      this.$emit('changeTab', tab);
    }
  }
}
</script>

<style scoped>

</style>
