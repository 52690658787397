const axios = require('axios');

//DEV http://localhost:8888/
//PRD https://online.innovision.com.tw/
const APIService = {
    baseDomain: 'https://us-central1-innovision-bf656.cloudfunctions.net/',
    apiDomain: 'https://innovision.com.tw/',
    // apiDomain: 'http://localhost:3000/',
    async getStoreList(){
        try {
            return await axios({
                method: 'post',
                url: `${this.apiDomain}store/list`,
                headers: {
                },
                data: { target:1 , pageIndex: 1 , limit: 10000 },
            });
        } catch (e) {
            console.log(`getStoreList error ${e}`);
            return e;
        }
    },
    async saveContactInfo(data){
        try {
            return await axios({
                method: 'post',
                url: `${this.apiDomain}contact/create`,
                headers: {
                },
                data: data.info,
            });
        } catch (e) {
            console.log(`saveContactInfo error ${e}`);
            return e;
        }
    },
    async getProductList(data){
        try {
            return await axios({
                method: 'post',
                url: `${this.apiDomain}product/list`,
                headers: {
                },
                data: data.info,
            });
        } catch (e) {
            console.log(`getProductList error ${e}`);
            return e;
        }
    },
    async getProductInfo(data){
        try {
            return await axios({
                method: 'post',
                url: `${this.apiDomain}product/info`,
                headers: {
                },
                data: data.info,
            });
        } catch (e) {
            console.log(`getProductList error ${e}`);
            return e;
        }
    },
    async getNewsList(data){
        try {
            return await axios({
                method: 'post',
                url: `${this.apiDomain}news/list`,
                headers: {
                },
                data: data
            });
        } catch (e) {
            console.log(`getNewsList error ${e}`);
            return e;
        }
    },
    async getNewsInfo(data){
        try {
            return await axios({
                method: 'post',
                url: `${this.apiDomain}news/info`,
                headers: {
                },
                data: data
            });
        } catch (e) {
            console.log(`getNewsInfo error ${e}`);
            return e;
        }
    },
    async getHomeSettingInfo(){
        try {
            return await axios({
                method: 'post',
                url: `${this.apiDomain}home/settings`,
                headers: {
                },
            });
        } catch (e) {
            console.log(`getHomeSettingInfo error ${e}`);
            return e;
        }
    },
    async getAboutInfo(){
        try {
            return await axios({
                method: 'get',
                url: `${this.apiDomain}about/info`,
                headers: {
                },
            });
        } catch (e) {
            console.log(`getAboutInfo error ${e}`);
            return e;
        }
    },
    async getSaleInfo(pageId){
        try {
            return await axios({
                method: 'get',
                url: `${this.apiDomain}sale/page?pageId=${pageId}`,
                headers: {
                },
            });
        } catch (e) {
            console.log(`getSaleInfo error ${e}`);
            return e;
        }
    }
}

export default APIService;
