<template>
  <div class="newsDiv">
    <div class="container">
      <b-row>
        <b-col cols="12">
          <h3><span class="tit-L">News&nbsp;&nbsp;&nbsp;&nbsp;</span>最新消息<br/><span class="tit-S">News</span></h3>
        </b-col>
      </b-row><!--row-->
      <b-row v-if="newsList.length">
        <b-col cols="12" sm="6" md="4" class="newsBox"
          v-for="item in newsList" :key="item.newsId">
          <div class="news-listImg">
            <img :src="item.smallURL" width="290px" height="221px" @click.prevent="showDetail(item.newsId)">
          </div>
          <div class="news-listDetail">
            <span class="news-date">{{ item.createDate }}</span>
            <h6>{{ item.title }}</h6>
            <p v-html="item.content">
            </p>
          </div><!--news-listDetail-->
          <a href="#" target="_self" class="moreBtn" @click.prevent="showDetail(item.newsId)"><span>READ MORE</span></a>
        </b-col><!--newsBox-->
      </b-row><!--row-->


      <b-row>
        <b-col cols="12" class="pageBar">
          <a href="#" target="_self" v-for="i in page.totalPage"
             class="pageBtn now" :key="i"
          @click.prevent="changePage(i)">{{i}}</a>
        </b-col><!--pageBar-->
      </b-row><!--row-->
    </div><!--container-->
  </div><!--end of newsDiv-->
</template>

<script>
import APIService from "@/utils/APIService";
import common from "@/mixins/common";
export default {
  name: "News",
  metaInfo:{
    title: '最新消息 - Innovision 優視達光學',
  },
  data(){
    return {
      newsList: [],
      page:{
        pageIndex: 1,
        limit: 10,
        totalPage: 1,
        totalCount: 0,
      },
    }
  },
  mixins:[common],
  created() {
    this.loadData();
  },
  mounted(){
    this.toTop();
  },
  computed:{
  },
  methods: {
    async loadData(){
      const res = await APIService.getNewsList({
        pageIndex: this.page.pageIndex,
        limit: this.page.limit,
        searchValue:'',
      });
      const result = res.data;
      if (result.code === 0){
        this.page.totalPage = result.body.header.totalPage;
        this.page.totalCount = result.body.header.totalCount;
        this.newsList = result.body.list;
        // console.log(`newsList == ${JSON.stringify(this.newsList)}`);
      }
    },
    changePage(index){
      this.page.pageIndex = index;
      this.loadData();
    },
    showDetail(newsId){
      this.$router.push({ path:'/newsDetail' , query: { id: newsId }});
    }
  }
}
</script>

<style scoped>

</style>
