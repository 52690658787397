<template>
  <b-row class="pro-contentIntro" v-if="product">
    <b-col cols="12" md="9" offset-md="3">
      <h5>{{ product.name }}</h5>
    </b-col>
    <b-col cols="12" md="9" offset-md="3">
      <div class="IntroBox">
        <img :src="product.largeURL"/>
        <div class="IntroTit">
          <span>{{ product.name }}</span>
        </div>
        <div class="IntroColor">
          <span>{{ product.color }}</span>
        </div>
        <div class="IntroPrice">
          TWD$<span>{{ product.price }}</span>
        </div>
         <a href="#" class="IntroAreaBtn" v-if="product.linkList && product.linkList.length > 0"
         @click="showLink(product.linkList)">立即選購</a>
        <router-link v-if="product.category !== 3 && product.category !== 4 && (!product.linkList || product.linkList.length === 0)"
                     to="/store" class="IntroAreaBtn">販售據點</router-link>
      </div>
    </b-col>
    <b-col cols="12" md="9" offset-md="3">
      <div class="IntroContentImg">
        <div class="pc" v-for="(image, i) in product.imageList" :key="`pc_${i}`">
          <img :src="image.url"/>
        </div>
        <div class="mobile" v-for="(image, i) in product.imageList" :key="`mobile_${i}`">
          <img :src="image.url"/>
        </div>
      </div>
      <div class="IntroContentTxt">
        <h6>規格說明：</h6>
        <p v-html="product.content"></p>
<!--        <p>-->
<!--          KARACON Soft Contact Lens-One day<br/>-->
<!--          卡拉瞳每日拋棄式隱形眼鏡<br/>-->
<!--          規格：10片裝 / 30片裝 直徑：14.0mm<br/>-->
<!--          含水量：38% / 基弧：8.7mm<br/>-->
<!--          度數： -0.10 ~ -10.00 (-1.00以上, 間隔0.25 ; -6.00以上, 間隔0.50)<br/>-->
<!--          全台部分康是美 上架開賣<br/>-->
<!--          全台部分日系Tomod's三友藥妝 上架開賣-->
<!--        </p>-->
<!--        <h6>廣字號：</h6>-->
<!--        <p>衛部醫器製字第005312號、衛部醫器製字第005946號、衛部醫器製字第005947號、衛部醫器製字第006128號、衛部醫器製字第006119號、衛部醫器製字第006208號、衛部醫廣製字第10803012號、衛部醫器廣字第10812014號、衛部醫器廣字第10812015號</p>-->
      </div>
    </b-col>
  </b-row><!--row-->
</template>

<script>
import APIService from "@/utils/APIService";
import product from "@/pages/product/product";
import NoticeManager from "@/utils/NoticeManager";
export default {
name: "ProductDetail",
  props:{
    productId: String,
  },
  data() {
    return {
      product: null,
    }
  },
  watch: {
    '$route.query.id'(value){
      console.log(`change id = ${value}`);
      this.loadData();
    }
  },
  created() {
    this.loadData();
  },
  mounted() {
  },
  computed:{
    getSmallImage(){
      return product.smallImage;
    },
    getPcImage(){
      return product.pcDetailURL;
    },
    getMobileImage(){
      return product.mobileDetailURL;
    }
  },
  methods:{
    async loadData(){
      const id = this.$route.query.id;
      const query = { productId: (id || id !=='') ? id :this.productId };
      const res = await APIService.getProductInfo({ info: query });
      const result = res.data;
      console.log(`loadData == ${JSON.stringify(query)}`);
      if (result.code === 0){
        this.product = result.body;
        console.log(`product == ${JSON.stringify(this.product)}`);
      }
    },
    showLink(list){
      NoticeManager.notifyObservers('showLink', {
        list: list
      });
    },
  }
}
</script>

<style scoped>

</style>
