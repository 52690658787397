<template>
  <div class="newsPageDiv">
    <div class="container">
      <b-row>
        <b-col cols="12">
          <h3><span class="tit-L">News&nbsp;&nbsp;&nbsp;&nbsp;</span>最新消息<br/><span class="tit-S">News</span></h3>
        </b-col>
      </b-row><!--row-->
      <b-row class="newsRow" v-if="currentNewsInfo">
        <b-col cols="12" md="6" class="newsImg">

          <b-carousel  controls
                       indicators
                       :role="`list`"
                       id="carousel-example-generic" class="carousel slide"
                       data-ride="carousel" v-model="slide">

            <b-carousel-slide>
              <template v-slot:img>
                <div class="item img1">
                  <img :src="currentNewsInfo.largeURL" width="100%" height="100%">
                </div>
              </template>
            </b-carousel-slide>

<!--            <b-carousel-slide>-->
<!--              <template v-slot:img>-->
<!--                <div class="img2 active">-->
<!--                  <img src="../../assets/images/news/news-pageImg.jpg">-->
<!--                </div>-->
<!--              </template>-->
<!--            </b-carousel-slide>-->

<!--            <b-carousel-slide>-->
<!--              <template v-slot:img>-->
<!--                <div class="item img3 active">-->
<!--                  <img src="../assets/images/news/news-pageImg.jpg">-->
<!--                </div>-->
<!--              </template>-->
<!--            </b-carousel-slide>-->

          </b-carousel>
        </b-col><!--newsImg-->
        <b-col cols="12" md="6" class="newsDetail">
          <h6>{{ currentNewsInfo.title }}</h6>
          <div class="scrollBox">
            <p v-html="currentNewsInfo.content"></p>
<!--            <p>手機版不使用捲軸，直接顯示全部文字</p>-->
          </div><!--scrollBox-->
          <a href="#" class="backBtn" @click.prevent="back">Back</a>
        </b-col><!--newsDetail-->
      </b-row><!--row-->
    </div><!--container-->
  </div><!--end of newsPageDiv-->
</template>

<script>
import APIService from "@/utils/APIService";
import common from "@/mixins/common";
export default {
  name: "NewsDetail",
  data(){
    return {
      newsList: [],
      slide: 0,
      currentNewsInfo: null,
    }
  },
  mixins:[common],
  created() {
    this.loadData();
  },
  mounted(){
    this.toTop();
  },
  methods:{
    async loadData(){

      const key = this.$route.query.id;
      const res = await APIService.getNewsInfo({ newsId : key});

      const result = res.data;
      // console.log(`getNewsInfo == ${JSON.stringify(this.result)}`);

      if (result.code === 0){
        this.currentNewsInfo = result.body;
      }
    },
    back(){
      this.$router.back();
    }
  }
}
</script>

<style scoped>

</style>
