<template>
  <div class="productDiv">
    <b-container>
      <!--productNav.html-->
      <ProductNav @changeMenu="changeMenu" @changeTab="changeTab"/>
      <!--productNav.html-->
      <Detail :productId="productId" />
    </b-container><!--container-->
  </div><!--end of productDiv-->
</template>

<script>
import ProductNav from "@/components/ProductNav";
import Detail from "@/pages/product/detail";
export default {
  name: "ProductDetail",
  components: { ProductNav, Detail,},
  metaInfo:{
    title: '產品介紹 - Innovision 優視達光學',
  },
  data(){
    return {
      tab: 0,
      showType: -1,
      group:'',
      groupName:'',
      disposable:'',
      productId: '',
    }
  },
  created() {
    this.tab = this.$route.query.tab;
    this.productId = this.$route.query.productId;
    // this.loadData();
  },
  methods:{
    changeMenu(key){
      this.showType = key;
      this.tab = 0;
      localStorage.removeItem('cache_query');
    },
    changeTab(key){
      this.tab = key;
      this.$router.push({ path:'/product', query: { tab: this.tab }});
    },
    showProductList(product){
      // this.$router.push({ path: 'productList' , query: { group: product.productGroup }});
      const query = { tab: 3, group: product.productGroup, groupName: product.name, disposable:product.disposable, enableType:1 };
      localStorage.setItem('cache_query', JSON.stringify(query));
      this.$router.push({ path:'/product', query: { tab: query.tab , group: query.group }});
      this.showToTop();
    }
  }
}
</script>

<style scoped>

</style>
