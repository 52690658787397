<template>
  <div class="productDiv">
    <b-container>
      <!--productNav.html-->
      <ProductNav @changeMenu="changeMenu" @changeTab="changeTab"/>
      <!--productNav.html-->
      <Product v-if="tab === 0" :showType="showType" @showDetail="showProductList"/>
      <Makeup v-if="tab === 1" @showDetail="showProductList"/>
      <Life v-if="tab === 2" @showDetail="showProductList"/>
      <Detail v-if="tab === -1" :productId="productId" />
      <ProductList :group="group" :groupName="groupName" :disposable="disposable"
                   @showDetail="showDetail" v-if="tab === 3"/>

    </b-container><!--container-->
  </div><!--end of productDiv-->
</template>

<script>
import ProductNav from "@/components/ProductNav";
import Product from "@/pages/product/product";
import ProductList from "@/pages/product/productList";
import Makeup from "@/pages/product/makeup";
import Life from "@/pages/product/life";
import Detail from "@/pages/product/detail";

export default {
  name: "index",
  components: {Life, Makeup, Product, ProductList, ProductNav, Detail},
  metaInfo:{
    title: '產品介紹 - Innovision 優視達光學',
  },
  data(){
    return {
      tab: 0,
      showType: -1,
      group:'',
      groupName:'',
      disposable:'',
      productId: '',
    }
  },
  created() {
    this.loadData();
  },
  mounted() {
    this.loadData();
  },
  watch: {
    $route(to, from) {
      console.log(`from === ${from.path} , to === ${to.path} / ${JSON.stringify(to.query)}`);

    }
  },
  methods:{
    async loadData(){
      this.tab = parseInt(this.$route.query.tab);
      this.showType = this.$route.query.menu ? parseInt(this.$route.query.menu) : -1;
    },
    changeMenu(key){
      this.showType = key;
      this.tab = 0;
      localStorage.removeItem('cache_query');
    },
    changeTab(key){
      this.tab = key;
      this.$router.replace({ path:'/product', query: { tab: this.tab}});
    },
    showProductList(query){
      this.tab = query.tab;
      this.group = query.group;
      this.disposable = query.disposable;
      delete query.groupName;
      console.log(`showProductList == ${JSON.stringify(query)}`);
      this.$router.push({ path:'/productGroup', query: query});
    },
    showDetail(query){
      console.log(`showDetail == ${JSON.stringify(query)}`);
      this.tab = query.tab;
      this.productId = query.productId;
      this.$router.push({ path:'/productInfo', query: { id: this.productId , tab: this.tab, group: query.group}});
    }
  }
}
</script>

<style scoped>

</style>
