<template>
  <div class="productDiv">
    <b-container>
      <!--productNav.html-->
      <ProductNav @changeMenu="changeMenu" @changeTab="changeTab"/>
      <!--productNav.html-->
      <ProductList :group="group" :groupName="groupName" :disposable="disposable"
                   @showDetail="showDetail"/>
    </b-container><!--container-->
  </div><!--end of productDiv-->
</template>

<script>
import ProductNav from "@/components/ProductNav";
import ProductList from "@/pages/product/productList";
export default {
  name: "ProductDetail",
  components: { ProductNav, ProductList},
  metaInfo:{
    title: '產品介紹 - Innovision 優視達光學',
  },
  data(){
    return {
      tab: 0,
      showType: -1,
      group:'',
      groupName:'',
      disposable:'',
      productId: '',
    }
  },
  created() {
    this.tab = this.$route.query.tab;
    this.group = this.$route.query.group;

    let cacheData = null;
    const cache = localStorage.getItem('cache_group');
    if (cache){
      cacheData = JSON.parse(cache);
    }
    // console.log(`showProductList cache ${this.group} == ${JSON.stringify(cache)}`);
    if (cacheData.length > 0){
      const findGroup = cacheData.find(i=>i.productGroup === this.group);
      if (findGroup){
        this.groupName = findGroup.name;
      }
    }
    // this.groupName = this.$route.query.groupName;
    this.disposable = parseInt(this.$route.query.disposable);
  },
  methods:{
    changeMenu(key){
      this.showType = key;
      this.tab = 0;
      localStorage.removeItem('cache_query');
    },
    changeTab(key){
      this.tab = key;
      this.$router.push({ path:'/product', query: { tab: this.tab }});
    },
    showDetail(query){
      console.log(`showDetail == ${JSON.stringify(query)}`);
      this.tab = query.tab;
      this.productId = query.productId;
      this.$router.push({ path:'/productInfo', query: { id: this.productId , tab: this.tab, group: query.group}});
    },
  }
}
</script>

<style scoped>

</style>
