<template>
  <div class="aboutDiv">
    <div class="container">
      <b-row>
        <b-col cols="12">
          <h3><span class="tit-L">ABOUT US&nbsp;&nbsp;&nbsp;&nbsp;</span>品牌故事<br/><span class="tit-S">ABOUT US</span>
          </h3>
        </b-col>
      </b-row><!--row-->
      <b-row style="display: block;" class="concept">
        <b-col cols="12" class="conceptBox">
          <img src="../../assets/images/about/img-tit-brand.jpg">
          <h6>{{ about.title }}</h6>
          <span v-html="about.content">
          </span>
        </b-col>
      </b-row><!--row-->
      <b-row style="display: block;" class="store">
        <b-col cols="12" class="storeBox">
          <b-col cols="12" sm="4">
            <img src="../../assets/images/about/img-tit-store.jpg" class="titImg">
          </b-col>
          <b-col cols="12" sm="8">
            <img :src="getBrandStoreList[0].pcURL" class="titStoreImg">
          </b-col>
          <b-col cols="12">
            <p v-html="about.storeContent"></p>
          </b-col>
          <b-row>
            <b-col cols="4" class="brandImgBox" v-for="(brand, i) in getBrandList " :key="i">
              <img :src="brand.pcURL" class="brandImg">
            </b-col>
          </b-row>
        </b-col>
      </b-row><!--row-->
      <b-row style="display: block;" class="history">
        <b-col cols="12" class="historyBox">
          <div class="ql-editor" v-html="about.history"></div>
        </b-col>
      </b-row><!--row-->
      <b-row style="display: block;" class="storeImg">
        <b-col cols="12" class="col-xs-12 storeImgBox">
          <ul>
            <li v-for="(store, i) in getStoreList" :key="i"><img :src="store.pcURL" class="storeImg"></li>
          </ul>
        </b-col>
      </b-row><!--row-->
    </div><!--container-->
  </div><!--end of aboutDiv-->
</template>

<script>
import APIService from "@/utils/APIService";
import Notification from "@/utils/NoticeManager";

export default {
  name: "about",
  metaInfo:{
    title: '品牌故事 - Innovision 優視達光學',
  },
  data(){
    return {
      home: null,
      about: null,
    }
  },
  created() {
    this.loadHomeData();
    this.loadAboutData();
  },
  mounted(){
    window.scrollTo(0, 600);
  },
  computed:{
    getStoreList(){
      if (this.home){
        return this.home.store;
      }
      return [];
    },
    getBrandStoreList(){
      if (this.home){
        return this.home.about;
      }
      return [];
    },
    getBrandList(){
      if (this.home){
        return this.home.brand;
      }
      return [];
    }
  },
  methods:{
    async loadHomeData(){
      const res = await APIService.getHomeSettingInfo();

      const result = res.data;
      console.log(`getHomeSettingInfo == ${JSON.stringify(result)}`);

      if (result.code === 0){
        this.home = result.body;
        Notification.notifyObservers('home', this.home);
      }
    },
    async loadAboutData(){
      const res = await APIService.getAboutInfo();

      const result = res.data;
      console.log(`getAboutInfo == ${JSON.stringify(result)}`);

      if (result.code === 0){
        this.about = result.body;
      }
    },
  }
}
</script>

<style scoped>

</style>
