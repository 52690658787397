<template>
  <div class="areaDiv">
    <div class="container">
      <b-row>
        <b-col cols="12">
          <h3>
            <span class="tit-L">AREA&nbsp;&nbsp;&nbsp;&nbsp;</span
            >銷售據點<br /><span class="tit-S">AREA</span>
          </h3>
        </b-col> </b-row
      ><!--row-->
      <b-row>
        <b-col cols="12" class="area-btnBox">
          <a
            href="#"
            target="_self"
            :class="tab === 0 ? 'areaBtn now' : 'areaBtn'"
            @click.prevent="changeTab(0)"
            >眼鏡光學通路</a
          >
          <a
            href="#"
            target="_self"
            :class="tab === 1 ? 'areaBtn now' : 'areaBtn'"
            @click.prevent="changeTab(1)"
            >藥局/藥妝</a
          >
        </b-col> </b-row
      ><!--row-->
      <b-row class="area-selectBox">
        <b-col cols="6" md="3">
          <select class="select" v-model="selectCity" @change="changeCity">
            <option
              v-for="(value, i) in cityList"
              :key="`city_${i}`"
              :value="value"
              >{{ value }}
            </option>
          </select>
        </b-col>
        <b-col cols="6" md="3">
          <select class="select" v-model="selectArea" @change="changeSelect">
            <option
              v-for="(area, i) in areaList[selectCity]
                ? areaList[selectCity]
                : []"
              :key="`${selectCity}_${i}`"
              :value="area.text"
              >{{ area.text }}
            </option>
          </select>
        </b-col>
        <b-col cols="6" md="3">
          <select class="select" v-model="selectProduct" @change="changeSelect">
            <option v-for="value in productItemList" :key="value" :value="value"
              >{{ value }}
            </option>
          </select>
        </b-col>
        <b-col cols="6" md="3">
          <select class="select" v-model="selectItem" @change="changeSelect">
            <option v-for="value in itemList" :key="value" :value="value"
              >{{ value }}
            </option>
          </select>
        </b-col>
      </b-row>
      <b-row
        v-for="(store, i) in filterList"
        :key="`store_${i}`"
        :class="
          store.show === true ? 'areaBox areaBox-open' : 'areaBox areaBox-close'
        "
      >
        <b-col cols="12" class="area-title"
          >{{ store.name }}&nbsp;&nbsp;&nbsp;&nbsp;{{ store.street }}
          <span style="font-size: 12px; float: right;"
            >實際販售品項請以現場為準</span
          >
          <a class="area-listBtn" @click.prevent="showProduct(store)"
            >販售<br />項目</a
          >
        </b-col>
        <b-col cols="12" md="5" class="area-address"
          >地址：<a
            href="#"
            target="_new"
            @click.prevent="showMap(store)"
            class="area-map"
            >{{ store.address }}</a
          ></b-col
        >
        <b-col cols="12" md="3" class="area-phone"
          >電話：<a href="tel:">{{ store.cellphone }}</a></b-col
        >
        <b-col cols="12" md="3" class="area-time"
          >營業時間：{{ store.time }}</b-col
        >
        <b-col cols="12" class="area-product">
          <a
            href="#"
            target="_self"
            class="area-productBtn"
            v-for="(product, i) in store.productList"
            :key="i"
            @click.prevent="toProductView(product)"
            >{{ getProductName(product) }}</a
          >
          <!--          <a href="#" target="_self" class="area-productBtn">日拋彩色隱形眼鏡</a>-->
        </b-col> </b-row
      ><!--row areaBox-->
      <b-row v-if="totalPage > 0">
        <b-col cols="12" class="pageBar">
          <a
            href="#"
            target="_self"
            v-for="i in totalPage"
            :key="i"
            :class="pageIndex === i ? 'pageBtn now' : 'pageBtn'"
            @click.prevent="changePage(i)"
            >{{ i }}</a
          >
          <!--                    <a href="#" target="_self" class="pageBtn">2</a>-->
          <!--                    <a href="#" target="_self" class="pageBtn">3</a>-->
          <!--                    <a href="#" target="_self" class="pageBtn">4</a>--> </b-col
        ><!--pageBar--> </b-row
      ><!--row-->
    </div>
    <!--container-->
  </div>
  <!--end of areaDiv-->
</template>

<script>
import APIService from "../../utils/APIService";
import common from "@/mixins/common";

export default {
  name: "Store",
  metaInfo: {
    title: "銷售據點 - Innovision 優視達光學",
  },
  data() {
    return {
      cityData: require("../../assets/taiwan_city.json"),
      cityList: [],
      areaList: {},
      tab: 0,
      loading: false,
      show: false,
      list: [],
      filterPullList: [],
      filterList: [],
      filterCount: 0,
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
      totalPage: 0,
      productItemList: [],
      itemList: [],
      selectCity: "全部縣市",
      selectArea: "全部區域",
      selectProduct: "全部系列",
      selectItem: "全部品項",
    };
  },
  mixins: [common],
  created() {
    this.loadCityData();
    this.loadData();
  },
  mounted() {
    this.toTop();
  },
  computed: {},
  methods: {
    toProductView(product) {
      this.$router.push({
        path: "/productInfo",
        query: { id: product.productId, group: product.productGroup },
      });
    },
    getProductName(product) {
      return `${product.color}`;
    },
    loadCityData() {
      this.cityList.push("全部縣市");
      this.areaList[this.selectCity] = [{ text: "全部區域" }];
      this.cityData.taiwan.forEach((city) => {
        this.cityList.push(city.city);
        this.areaList[city.city] = [];
        this.areaList[city.city].push({ text: "全部區域" });
        city.area.forEach((area) => {
          this.areaList[city.city].push(area);
        });
      });
    },
    reset() {
      this.selectCity = "全部縣市";
      this.selectArea = "全部區域";
      this.selectProduct = "全部系列";
      this.selectItem = "全部品項";
    },
    changeCity() {
      this.areaList["全部區域"] = [{ text: "全部區域" }];
      this.selectArea = "全部區域";
      this.filterData();
    },
    changeSelect() {
      console.log(
        `changeSelect === ${this.selectCity} / ${this.selectArea} / ${this.selectProduct}`
      );
      this.filterData();
    },
    filterData() {
      const defaultCity = "全部縣市";
      const defaultArea = "全部區域";
      const defaultProduct = "全部系列";
      const defaultItem = "全部品項";
      let list = [];
      const productList = new Set();

      const filterTabList = this.list.filter((s) => s.storeType === this.tab);
      filterTabList.forEach((store) => {
        if (store.productList) {
          store.productList.forEach((p) => {
            productList.add(p.name);
          });
        }
      });
      filterTabList.forEach((store) => {
        if (
          this.selectCity === defaultCity &&
          this.selectArea === defaultArea &&
          this.selectProduct === defaultProduct
        ) {
          list.push(store);
          return true;
        } else {
          if (
            this.selectCity !== defaultCity &&
            this.selectArea !== defaultArea &&
            this.selectProduct !== defaultProduct
          ) {
            // console.log('check 1');
            if (
              store.city.trim() === this.selectCity.trim() &&
              store.street.trim() === this.selectArea &&
              this.checkContainProduct(store)
            ) {
              list.push(store);
              return true;
            }
          } else if (
            this.selectCity !== defaultCity &&
            store.city.trim() === this.selectCity.trim() &&
            this.selectArea === defaultArea
          ) {
            // console.log('check 2');
            // console.log(`${store.city.trim()} === ${this.selectCity.trim()} ${store.name} | type ${store.storeType}`);
            list.push(store);
            return true;
          } else if (
            this.selectCity !== defaultCity &&
            this.selectArea !== defaultArea &&
            store.city.trim() === this.selectCity.trim() &&
            store.street.trim() === this.selectArea &&
            this.selectProduct === defaultProduct
          ) {
            // console.log('check 3');
            // console.log(`${store.street.trim()} === ${this.selectArea.trim()} ${store.name} | type ${store.storeType}`);
            list.push(store);
            return true;
          } else if (
            this.selectCity === defaultCity &&
            this.selectArea === defaultArea &&
            this.selectProduct !== defaultProduct
          ) {
            // console.log('check 4');
            if (this.checkContainProduct(store)) {
              list.push(store);
              return true;
            }
          } else if (
            this.selectCity !== defaultCity &&
            this.selectArea === defaultArea &&
            this.selectProduct !== defaultProduct
          ) {
            // console.log('check 5');
            if (
              store.city.trim() === this.selectCity.trim() &&
              this.checkContainProduct(store)
            ) {
              list.push(store);
              return true;
            }
          }
          return false;
        }
      });

      const copyItemList = new Set();
      list.forEach((store) => {
        if (store.productList) {
          store.productList.forEach((p) => {
            if (
              p.type !== 0 &&
              ((this.selectProduct !== defaultProduct &&
                this.selectProduct === p.name) ||
                this.selectProduct === defaultProduct)
            ) {
              copyItemList.add(p.color);
            }
          });
        }
      });

      if (this.selectItem !== defaultItem) {
        list = list.filter((store) => this.checkContainColor(store));
      }

      this.setMenu(productList);
      this.setItems(copyItemList);
      // this.filterList = list;
      this.filterPullList = list;
      this.filterCount = this.filterPullList.length;
      this.setPage();
      this.changePage(1);
    },
    checkContainProduct(store) {
      let searchValue = "";
      console.log(`products ${JSON.stringify(store)}`);
      if (store.productList) {
        store.productList.forEach((p) => {
          searchValue += ` ${p.name}`;
        });
        // console.log(`searchValue == ${searchValue} / ${this.selectProduct.toLowerCase()}`);
        const check =
          searchValue.toLowerCase().indexOf(this.selectProduct.toLowerCase()) >
          -1;
        return check;
      }
    },
    checkContainColor(store) {
      let searchValue = "";
      if (store.productList) {
        store.productList.forEach((p) => {
          if (p.type !== 0) {
            searchValue += ` ${p.color}`;
          }
        });
        const check =
          searchValue.toLowerCase().indexOf(this.selectItem.toLowerCase()) > -1;
        return check;
      }
    },
    changeTab(tab) {
      this.tab = tab;
      this.selectProduct = "全部系列";
      this.selectItem = "全部品項";
      this.filterData();
    },
    async loadData() {
      const res = await APIService.getStoreList();
      console.log(`result ${JSON.stringify(res.data.body.list)}`);
      this.list = res.data.body.list;
      this.filterList = [];
      this.filterPullList = [];

      this.list.forEach((store) => {
        store.show = false;
        if (store.storeType === this.tab) {
          this.filterPullList.push(store);
        }
        // if (store.products) {
        //   const products = store.products.split(",");
        //   store.products = products;
        // }
      });
      this.filterCount = this.filterPullList.length;
      console.log(`this.filterCount === ${this.filterCount}`);
      // this.setMenu(productList);
      this.filterData();
      this.setPage();
      this.changePage(1);
      // console.log(JSON.stringify(res));
    },
    setMenu(productList) {
      let products = [];
      products.push("全部系列");
      productList.forEach((value) => {
        products.push(value);
      });
      this.productItemList = products;
    },
    setItems(copyItemList) {
      let products = [];
      products.push("全部品項");
      copyItemList.forEach((value) => {
        products.push(value);
      });
      this.itemList = products;
    },
    setPage() {
      this.totalPage = Math.ceil(this.filterCount / this.pageSize);
      this.totalCount = this.filterCount;
    },
    changePage(i) {
      this.pageIndex = i;
      const beginIndex = this.pageIndex * this.pageSize - this.pageSize;
      const endIndex =
        this.totalCount > this.pageIndex * this.pageSize
          ? this.pageIndex * this.pageSize
          : this.totalCount;

      this.filterList = [];

      this.filterPullList.forEach((store, i) => {
        if (i >= beginIndex && i < endIndex) {
          this.filterList.push(store);
        }
      });
      console.log(`index ${this.filterPullList.length} | ${this.filterList.length}
       ${this.filterList.length} ,  ${beginIndex} / ${endIndex}`);
    },
    showMap(store) {
      const link = document.createElement("a");
      link.href = `https://www.google.com/maps?q=${store.address},${store.name}`;
      link.target = "_new";
      link.click();
    },
    showProduct(store) {
      store.show = !store.show;
      console.log(`showProduct show == ${store.show}`);
      this.$forceUpdate();
    },
  },
};
</script>

<style scoped></style>
