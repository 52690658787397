<template>
  <div class="productDiv" v-if="sale">
    <b-container>
      <b-row class="pro-contentIntro" >
        <b-col cols="12" md="9" offset-md="3">
          <div class="IntroContentImg">
            <div class="pc">
              <img :src="sale.imageURL"/>
            </div>
            <div class="mobile">
              <img :src="sale.imageURL"/>
            </div>
          </div>
          <div class="IntroContentTxt">
            <h6>{{ sale.title }}</h6>
            <p v-html="sale.content"></p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>

</template>

<script>
import APIService from "@/utils/APIService";
import Notification from "@/utils/NoticeManager";

export default {
  name: "sale",
  metaInfo:{
    title: '促銷 - Innovision 優視達光學',
  },
  data(){
    return {
      home: null,
      sale: null,
      pageId: null,
    }
  },
  created() {
    // this.loadHomeData();
    this.loadSaleData();
  },
  mounted(){
    window.scrollTo(0, 600);
  },
  computed:{
    getStoreList(){
      if (this.home){
        return this.home.store;
      }
      return [];
    },
    getBrandStoreList(){
      if (this.home){
        return this.home.about;
      }
      return [];
    },
    getBrandList(){
      if (this.home){
        return this.home.brand;
      }
      return [];
    }
  },
  methods:{
    async loadHomeData(){
      const res = await APIService.getHomeSettingInfo();

      const result = res.data;
      console.log(`getHomeSettingInfo == ${JSON.stringify(result)}`);

      if (result.code === 0){
        this.home = result.body;
        Notification.notifyObservers('home', this.home);
      }
    },
    async loadSaleData(){
      this.pageId = this.$route.query.pageId;
      if (this.pageId && this.pageId !== ''){
        const res = await APIService.getSaleInfo(this.pageId);

        const result = res.data;
        console.log(`getSaleInfo == ${JSON.stringify(result)}`);

        if (result.code === 0){
          this.sale = result.body;
        }
      }
    },
  }
}
</script>

<style scoped>

</style>
