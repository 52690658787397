<template>
  <b-row class="pro-content">
    <b-col cols="12" md="9" offset-md="3">
      <h6>生活小物</h6>
      <!--491px以上顯示product-1-->
      <div id="product-1" class="carousel slide" data-ride="">
        <!-- Indicators -->
        <ol class="carousel-indicators" v-if="getGroup0.totalPage > 0">
          <li data-target="#product-1" data-slide-to="0" class="active" v-for="i in getGroup0.totalPage"
              :key="i" @click.prevent="changePage(0, i)"> {{ i }}
          </li>
        </ol>
        <!-- Wrapper for slides -->
        <div class="carousel-inner" role="listbox">
          <b-row class="item active">
            <b-col cols="6" sm="4" class="box" v-for="product in getGroup0.filterList" :key="product.productId">
              <a href="#" class="listBox" @click.prevent="showProductList(product)">
                <div class="imgBox">
                  <img :src="product.smallURL" class="img-lg">
                  <img :src="product.smallHoverURL" class="img-lg-hover">
                </div>
                <div class="btnBox">
                  {{ product.name }}
                </div>
                <div class="price">TWD$<span>{{ product.price }}</span></div>
              </a><!--listBox-->
            </b-col>
          </b-row>
        </div>
      </div><!-- product-1 slide-->
      <!--490px以下顯示product-1-m-->
      <div id="product-1-m" class="carousel slide" data-ride="">
        <!-- Indicators -->
        <ol class="carousel-indicators" v-if="getGroup0.totalPage > 0">
          <li data-target="#product-1" data-slide-to="0" :class="getGroup0.index === i ? 'active': ''"
              v-for="i in getGroup0.totalPage"
              :key="i" @click.prevent="changePage(0, i)">{{ i }}</li>
        </ol>
        <!-- Wrapper for slides -->
        <div class="carousel-inner" role="listbox">
          <b-row class="item active">
            <b-col cols="6" sm="4" class="box" v-for="product in getGroup0.filterList" :key="product.productId">
              <a href="#" class="listBox" @click.prevent="showProductList(product)">
                <div class="imgBox">
                  <img :src="product.smallURL" class="img-lg">
                  <img :src="product.smallHoverURL" class="img-lg-hover">
                </div>
                <div class="btnBox">
                  {{ product.name }}
                </div>
                <div class="price">TWD$<span>{{ product.price }}</span></div>
              </a><!--listBox-->
            </b-col>
          </b-row>
        </div>
      </div><!-- product-1-m slide-->
    </b-col>
    <!--    <b-col cols="12" md="9" offset-md="3">-->
    <!--      <a href="#" target="_blank" class="makeupLink">-->
    <!--        <img src="../../assets/images/product/prepare.jpg">-->
    <!--      </a>-->
    <!--    </b-col>-->
  </b-row><!--row-->
</template>

<script>
import APIService from "@/utils/APIService";
import common from "@/mixins/common";
export default {
  name: "life",
  data() {
    return {
      products:[],
      groups0 :{
        index: 1,
        limit: 3,
        total: 0,
        totalPage: 0,
        list: [],
        filterList:[],
      },
    }
  },
  mixins:[common],
  created() {
    this.toTop();
    if (this.isMobile){
      this.getGroup0.limit = 4;
    }
    this.loadData();
  },
  computed: {
    getProductList() {
      return this.products;
    },
    getGroup0(){
      return this.groups0;
    },
  },
  methods: {
    showToTop() {
      if (this.isMobile) {
        this.toTop();
      }
    },
    async loadData(){
      const query = { pageIndex: 1, limit: 100, type: 0, searchBy:'category', searchValue: 4 };
      const res = await APIService.getProductList({ info: query });

      this.group = this.$route.query.group;

      const result = res.data;
      console.log(`loadData == ${JSON.stringify(query)}`);
      if (result.code === 0){
        this.page = result.body.header;
        this.products = result.body.list;
        console.log(`page == ${JSON.stringify(this.page)}`);
        console.log(`products == ${JSON.stringify(this.products)}`);

        for (let product of this.products){
          this.groups0.list.push(product);
        }

        this.groups0.total = this.groups0.list.length;
        this.groups0.totalPage = Math.ceil(this.groups0.total / this.groups0.limit);
        this.setGroupList(this.groups0);
      }
    },
    setGroupList(group){
      const begin = (group.index - 1) < 0 ? 0 : (group.index - 1) * group.limit;
      const end = group.total > (group.index * group.limit) ?
          group.index  * group.limit :
          group.total ;
      console.log(`page ${begin} / ${end}`);
      group.filterList = group.list.slice(begin, end);
    },
    changePage(index, page){
      console.log(`changePage == ${index} , ${page}`);
      if (index === 0){
        this.groups0.index = page;
        this.setGroupList(this.groups0);
      }
    },
    showProductList(product){
      // this.$router.push({ path: 'productList' , query: { group: product.productGroup }});
      const query = { tab: 3, group: product.productGroup, groupName: product.name, disposable:product.disposable };
      console.log(`showProductList == ${JSON.stringify(query)}`);
      localStorage.setItem('cache_query', JSON.stringify(query));
      this.$emit('showDetail', query);
      this.showToTop();
    }
  }
}
</script>

<style scoped>

</style>
