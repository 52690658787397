<template>
  <div class="indexDiv">
    <div class="news">
      <div class="container newsContent">
        <b-row>
          <b-col cols="12" sm="5" class="newsAd"><img v-if="getNewsInfo" :src="getNewsInfo.pcURL"
                                                      class="img-xs"></b-col><!--newsAd-->
          <b-col cols="12" sm="7" class="newsList">
            <h3>NEWS</h3>
            <ul>
              <li v-for="(news, i) in newsList" :key="i">
                <a href="#" target="_self" @click.prevent="showDetail(news.newsId)">{{ news.title }}<span class="arrow"></span></a></li>
            </ul>
          </b-col><!--newsList-->
        </b-row><!--row-->
      </div><!--container newsContent-->
    </div><!--news-->
    <div class="newsItem">
      <div class="container newsItemContent">
        <b-row>
          <b-col cols="12" class="newsItem-title">
            <img src="../../assets/images/index/newItem-title.jpg">
          </b-col>
        </b-row>
        <b-row class="newsItemBox" style="flex-wrap: nowrap;">
          <b-col md="4" class="newsItem-ad-s" v-for="(image, i) in getNewProductList" :key="i">
            <a href="#" target="_self" @click.prevent="showProductDetail(image)"><img :src="image.pcURL"></a>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="newsItem-ad-l"  v-for="(image, i) in getIntroductionList" :key="i">
            <a href="#" target="_self" @click.prevent="showProductDetail(image)"><img :src="image.pcURL"></a>
          </b-col>
        </b-row>
      </div><!--container newsItemContent-->
    </div><!--newsItem-->
    <div class="yourStyle">
      <div class="container yourStyleContent">
        <b-row>
          <b-col cols="12" class="yourStyle-title">
            <img src="../../assets/images/index/yourStyle-title.jpg">
          </b-col>
        </b-row>
        <b-row class="yourStyleBox" style="flex-wrap: nowrap;">
          <b-col md="4" class="yourStyleItem" v-for="(image, i) in getStyleList" :key="i">
            <a href="#" target="_self" @click.prevent="showProductDetail(image)">
              <span class="styleItem-tit">{{ image.title }}</span>
              <img :src="image.pcURL">
            </a>
          </b-col>
        </b-row>
      </div><!--container yourStyleContent-->
    </div><!--yourStyle-->
    <div class="storeBox">
      <div class="storeBoxContent">
        <div class="storeBox-title">
          <img src="../../assets/images/index/store-title.jpg">
        </div>
        <p class="text-lg">INNOVISION在線商品在台灣藥妝屈臣氏、康是美<br/>
          、松本清藥妝、札幌藥妝及特約眼鏡行等,<br/>
          約有<b>1000+店</b>在亞洲線上及實體通路銷售。</p>
        <p class="text-xs">INNOVISION在線商品在台灣藥妝康是美<br/>
          、松本清藥妝、札幌藥妝及特約眼鏡行等,<br/>
          約有<b>1000+店</b>在亞洲線上及實體通路銷售。</p>
        <ul class="storeBoxList">
          <li v-for="store of getStoreList" :key="store.imageId"><img :src="store.pcURL"></li>
<!--          <li><img src="../../assets/images/index/store-1.jpg"></li>-->
<!--          <li><img src="../../assets/images/index/store-3.jpg"></li>-->
<!--          <li><img src="../../assets/images/index/store-4.jpg"></li>-->
<!--          <li><img src="../../assets/images/index/store-5.jpg"></li>-->
<!--          <li><img src="../../assets/images/index/store-1.jpg"></li>-->
        </ul>
        <router-link to="/store" class="btnSale">銷售據點</router-link>
<!--        <a href="area.html" target="_self" class="btnSale">銷售據點<span></span></a>-->
      </div><!--storeBoxContent-->
    </div><!--storeBox-->

  </div><!--end of indexDiv-->
</template>

<script>

  import APIService from "@/utils/APIService";
  import Notification from '@/utils/NoticeManager';
  import common from "@/mixins/common";
  export default {
    name: 'Home',
    mixins:[common],
    data(){
      return {
        home: null,
        newsList: [],
      };
    },
    created() {
      this.toTop();
      this.loadData();
      this.loadNewsData();
    },
    computed: {
      getNewsInfo(){
        if (this.home){
          return this.home.news[0];
        }
        return null;
      },
      getNewProductList(){
        if (this.home){
          return this.home.newProducts;
        }
        return [];
      },
      getIntroductionList(){
        if (this.home){
          return this.home.introduction;
        }
        return [];
      },
      getStoreList(){
        if (this.home){
          return this.home.homeStore;
        }
        return [];
      },
      getStyleList(){
        if (this.home){
          return this.home.style;
        }
        return [];
      }
    },
    methods:{
      async loadData(){

        const res = await APIService.getHomeSettingInfo();

        const result = res.data;
        console.log(`getHomeSettingInfo == ${JSON.stringify(result)}`);

        if (result.code === 0){
          this.home = result.body;
          Notification.notifyObservers('home', this.home);
        }
      },
      async loadNewsData(){
        const res = await APIService.getNewsList({
          pageIndex: 1,
          limit: 4,
          searchValue:'',
        });
        const result = res.data;
        if (result.code === 0){
          this.newsList = result.body.list;
          // console.log(`newsList == ${JSON.stringify(this.newsList)}`);
        }
      },
      showDetail(newsId){
        this.$router.push({ path:'/newsDetail' , query: { id: newsId }});
      },

    }
  }
</script>
