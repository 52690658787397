<template>
  <b-row class="pro-contentList">
    <b-col cols="12" md="9" offset-md="3">
      <h6>{{ groupName }}</h6>
      <b-row>
        <b-col cols="6" md="4" class="listBox" v-for="product in product.list" :key="product.productId">
          <a href="#" class="listLink" @click.prevent="showDetail(product)">
            <span class="listImg"><img :src="product.largeURL"></span>
            <span class="listTit">{{ product.color }}</span>
            <span class="listPrice">TWD$<span>{{ product.price }}</span></span>
          </a>
        </b-col><!--listBox-->
      </b-row><!--row-->
      <b-row>
        <b-col cols="12" class="pageBar">
          <a href="#" target="_self"  v-for="i in product.totalPage"  :key="i"
             :class="product.index === i ? 'pageBtn now' : 'pageBtn'"
             @click="changePage(i)">{{ i }}</a>
        </b-col><!--pageBar-->
      </b-row>

    </b-col>
  </b-row><!--row-->
</template>

<script>
import APIService from "@/utils/APIService";
export default {
name: "ProductList",
  props:{
    groupName: String,
    group: String,
    disposable: Number,
  },
  data() {
    return {
      page:{},
      product: {
        index: 1,
        limit: 6,
        total: 0,
        totalPage: 0,
        list: [],
        filterList: [],
      },
    }
  },
  created() {
    this.loadData();
  },
  methods:{
    async loadData(){
      const cacheQuery = JSON.parse(localStorage.getItem('cache_query'));
      let showGroup = false;
      if (cacheQuery){
        showGroup = cacheQuery.enableType === 1 || cacheQuery.enableType === '1' || this.$route.path.indexOf('productGroup') > -1;
      }
      console.log(`${showGroup} cacheQuery == ${JSON.stringify(cacheQuery)}`);
      const query = { pageIndex: 1, limit: 100, type: 1
        , searchBy: 'productGroup', searchValue: this.group, sortBy:'sort', sortByValue: 'ASC' };
      const res = await APIService.getProductList({ info: query });

      const result = res.data;
      console.log(`loadData == ${JSON.stringify(query)}`);
      if (result.code === 0){
        this.page = result.body.header;
        const sort = result.body.list.sort((s1, s2)=> {
          if (s1.sort > s2.sort) return 1;
          if (s1.sort < s2.sort) return -1;
          return 0;
        });
        if (showGroup){
          this.product.list = sort.sort((s1, s2)=> s1.sort > s2.sort);
        } else {
          this.product.list = sort.filter(p=>p.disposable === this.disposable).sort((s1, s2)=> s1.sort > s2.sort);
        }
        console.log(`page == ${JSON.stringify(this.page)} \n ${this.product.list.length}`);
        // console.log(`products == ${JSON.stringify(this.products)}`);
        this.product.total = this.page.totalCount;
        this.product.totalPage = this.page.totalPage;
        this.$forceUpdate();
      }
    },
    changePage(i){
      this.product.index = i;
      this.loadData();
    },
    showDetail(product){
      const query = { tab: -1, productId: product.productId, group:this.group };
      localStorage.setItem('cache_query', JSON.stringify(query));
      this.$emit('showDetail', query);
    }
  }
}
</script>

<style scoped>

</style>
